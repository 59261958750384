$(document).ready(function () {

    $(document).scroll(function () {
        if ($(document).scrollTop() > 100) {
            $("header").addClass("scrollingdown");
        } else if ($(document).scrollTop() === 0) {
            $("header").removeClass("scrollingdown");
        }
    });

    $('.subnav').on('mouseenter', function (){
        let sibling = $(this).parent().find('.link--io')
        sibling.addClass('active')
    })
    $('.subnav').on('mouseleave', function (){
        let sibling = $(this).parent().find('.link--io')
        sibling.removeClass('active')
    })
    /*
    MENU RESPONSIVE
     */
    $('#menu').on('click', function () {
        /*Ouvre le menu*/
        $('#nav').slideToggle();

        /*Change le picto*/
        let src = $(this).attr('src');
        let newsrc = (src == '/img/icones/menu.svg') ? '/img/icones/close.svg' : '/img/icones/menu.svg';
        $(this).attr('src', newsrc);

        /* Remonte tous les sous-menu */
        $('nav ul li > ul').slideUp();
    })
    /* Ouverture des sous-menu */
    if ($(window).width() < 1025) {
        $('#nav ul li a').on('click', function (e) {
            if ($(this).siblings('ul').length > 0) {
                e.preventDefault();
                $(this).siblings('ul').slideToggle();
                $(this).unbind("click");
            }
        })
    }

    /*
    INPUT DYNAMIQUES
    */
    $('.input-checkbox span, .input-radio span, .input-switch span').on('click', function () {
        let input = $(this)[0].previousElementSibling
        input.checked = !input.checked;
    })

    /*
    ONGLETS
     */
    $('.onglet_title-item').on('click', function (e) {
        e.preventDefault();
        $('.onglet_title-item').removeClass('active')
        $(this).addClass('active');
        let title = $(this).data('title');
        $('.onglet_content-item').addClass('hidden');
        $('.onglet_content-item[data-content="' + title + '"]').removeClass('hidden');
    })


    /*
    TOGGLE PASSWORD
     */
    $('#show-pass').on('click', function () {
        //Change l'attribut du champ
        let attr = $(this).siblings('input').attr('type')
        let newAttr = (attr === 'password') ? 'text' : 'password';
        $(this).siblings('input').attr('type', newAttr)

        //Change le picto
        let classList = $(this).attr('class')
        let newClass = (classList === 'fas fa-eye cursor-pointer') ? 'fas fa-eye-slash cursor-pointer' : 'fas fa-eye cursor-pointer';
        $(this).attr('class', newClass);
    })

    $('.last').on('click', function () {
        if($('.gallery-items').hasClass('hidden')){
            $('.gallery-items').fadeIn();
        }
        $(this).removeClass('last')
    })
    $('.filter-presta div').on('click', function (){
        let data = $(this).data('cat');
        let filter = $(this)
        $('.gallery-items').each(function (i){
            if(filter.hasClass('active')){
                if(i >= 6){
                    $(this).hide('normal')
                }
                else{
                    $(this).show('normal')
                }
                if(i == 5){
                    $(this).addClass('last')  ;console.log($(this))
                }
                else{
                    $(this).removeClass('last')
                }
            }
            else{
                $('.gallery-items').removeClass('last')
                if($(this).data('cat') == data){
                    $(this).show('normal')
                }
                else{
                    $(this).hide('normal')
                }
            }
        })
    })

    /*
    ENLEVE STYLE P > IMG
    */
    $('p').has('img').css('all', 'unset');

    /*
    POPUP
     */
    $('.popup-open').on('click', function (e) {
        e.preventDefault();
        let id = $(this).data('id');
        $('#popup-' + id).fadeIn().css('display', 'grid')
    })
    /* Fermeture de la popup */
    $('.popup-close').on('click', function () {
        $('.popup').fadeOut();
    })
    $('.popup').on('click', function (e) {
        let popup = $('.popup-content');
        if (!popup.is(e.target) && popup.has(e.target).length === 0) {
            $('.popup').fadeOut();
        }
    });
    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            $('.popup').fadeOut();
        }
    });

    $('.gallery-img').colorbox({
        rel:'gallery-img',
        maxWidth: '95%',
        maxHeight: '95%',
        current: "image : {current} / {total}"
    })

    //aside
    $('.container-svg').on('mouseover', function (e) {
        e.preventDefault();
        $(this).find('.svg-content').parent().css('margin-right', '0rem');
        $(this).find('.svg-content').stop().show('normal');
    })
    $('.container-svg').on('mouseout', function (e) {
        e.preventDefault();
        $(this).find('.svg-content').parent().css('margin-right', '-0.75rem');
        $(this).find('.svg-content').stop().hide('normal');
    })

    /* filter realisation */
    $('.filter>div').on('click', function (e) {
        if($(this).hasClass('active')){
            $('.filter>div').removeClass('active')
        }
        else{
            $('.filter>div').removeClass('active')
            $(this).addClass('active')
        }
    });
});

// Cookies
window.messageCookies = function (valid) {
    if (valid == "1" || valid == undefined) {
        $.ajax({
            type: 'POST',
            dataType: 'JSON',
            url: Routing.generate("message_cookies")
        }).done(function () {
            $('#mention_cookies').hide();
        })
    }
}

/*
SLIDERS
 */
import Swiper, {Navigation, Pagination, Thumbs, Autoplay, EffectFade} from 'swiper';

Swiper.use([Navigation, Pagination, Thumbs, Autoplay, EffectFade]);

/* Slider hero */
const swiperHero = new Swiper('#slider-hero', {
    loop: true,
    speed:1000,
    autoplay: {
        delay:3500
    },
    navigation: {
        nextEl: '.swiper-hero_next',
        prevEl: '.swiper-hero_prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
})

let menu = ['Marquage <br>Véhicules', 'Enseignes <br> Vitrophanie', 'Supports de<br> communication', 'Signal&#233tiques', 'Marquage <br>textiles', 'Autres']

let swiperDemo = new Swiper('#slider-demo', {
    slidesPerView: 1,
    // loop: true,
    speed:800,
    autoplay: true,
    spaceBetween:80,
    centeredSlides: true,
    navigation: {
        nextEl: '.swiper-demo_next',
        prevEl: '.swiper-demo_prev',
    },
    pagination: {
        el: '.swiper-pagination-demo',
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (menu[index]) + '</span>';
        },
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            centeredSlides: false,
            spaceBetween:25,
        },
        1024: {
            slidesPerView: 3,
            centeredSlides: true,
            spaceBetween:25,
        }

    },
});



const swiperPartenaires = new Swiper('#slider-part', {
    slidesPerView:1,
    loop: true,
    autoplay: true,
    navigation: {
        nextEl: '.swiper-part_next',
        prevEl: '.swiper-part_prev',
    },
    breakpoints:{
        1024:{
            slidesPerView:5,
            spaceBetween: 10,
        }
    }
})
import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


ScrollTrigger.create({
    start: 'top -80',
    end: 99999,
    marker: true,
    toggleClass: {className: 'logo-header-scrolled', targets: '.logo-header'}
});
