/**
 * Boutique
 * préfixe : bout_
 */

/**
 * Init
 */
$(document).ready(function () {
    if($('select.select-gamme').length > 0) bout_majArticleDeclinaison();
    else if($('#cadre_stock_0').is(":visible")) bout_activeBtAjoutPanier(false);
});

/**
 * MAJ de l'article en fonction de la déclinaison
 * <select> avec class select-gamme
 */
$(document).on('change', 'select.select-gamme', function(){
    bout_majArticleDeclinaison();
});

window.bout_majArticleDeclinaison = function() {

    let id_article = $('#champ_id_article').val();
    let id_gammes = [];
    $('select.select-gamme').each(function() {
        id_gammes.push($(this).val());
    });
    id_gammes = id_gammes.join(",");

    // Désactivation gammes + prix + ajout panier
    $('select.select-gamme option').attr('disabled', true);
    $('#prix_article').html('');
    $('#cadre_prix_barre').hide();
    $('#prix_barre_article').html('');
    bout_activeBtAjoutPanier(false);

    // AJAX : envoi article, gammes
    $.ajax({
        url:Routing.generate("article-detail-maj-declinaison", {id_article:id_article, id_gammes:id_gammes}),
        type:'POST',
        dataType:'JSON'
    }).done(function(data) {
        // Gammes
        if(undefined != data.select_gammes) $('#select-gammes').html(data.select_gammes);
        // Prix
        $('#prix_article').html(data.prix);
        if(data.prixBarre != undefined && data.prixBarre > 0) {
            $('#cadre_prix_barre').show();
            $('#prix_barre_article').html(data.prixBarre);
        }
        // Stock
        $('#cadre_stock_1').hide();
        $('#cadre_stock_0').hide();
        if(data.stock > 0) {
            $('#cadre_stock_1').show();
            bout_activeBtAjoutPanier(true);
        }
        else $('#cadre_stock_0').show();
    });

}

/**
 * Panier
 */
$(document).on('change keyup paste', '.cart-qty', function(){
    bout_modifQtyArticlePanier($(this).data('id'), $(this).val());
});
$(document).on('click', '#bt-bon-reduction', function(){
    bout_checkBon($('#input-bon-reduction').val());
});

window.bout_activeBtAjoutPanier = function(active) {
    if(active == true) {
        $('#bt_ajout_panier').attr("disabled", false);
        $('#bt_ajout_panier').css("opacity", "1");
    } else {
        $('#bt_ajout_panier').attr("disabled", true);
        $('#bt_ajout_panier').css("opacity", "0.4");
    }
}

/**
 * Ajout au panier
 */
$(document).on('click', 'input.ajout-panier', function(){

    let gamme = 0;
    if($('#id_gamme').length > 0 && $('#id_gamme').val() != undefined) gamme = $('#id_gamme').val();

    $.ajax({
        type: 'POST',
        dataType:'JSON',
        url: Routing.generate("panier-ajout", {id_article:$(this).data('id'), gamme:gamme, quantite:$('#qtt').val()})
    }).done(function(e) {
        $.colorbox({
            maxWidth: "95%",
            maxHeight: "95%",
            innerWidth: "75%",
            innerHeight: "50%",
            closeButton: false,
            fixed: true,
            href: Routing.generate("panier-popup"),
            className: 'colorbox'
        });
    });
})

/**
 * Modification d'un article dans le panier
 */
window.bout_modifQtyArticlePanier = function (i_ligne, quantite)
{
    $.ajax({
        url:Routing.generate("panier-modif-qte", {i_ligne:i_ligne, quantite: quantite}),
        type:'POST',
        dataType:'JSON'
    }).done(function(data){
        $('#panier-contenu').html(data.panier)
    });
}

/**
 * Suppression d'un article dans le panier
 */
window.bout_supprArticlePanier = function (i_ligne)
{
    $.ajax({
        url:Routing.generate("panier-suppr", {i_ligne:i_ligne}),
        type:'POST',
        dataType:'JSON'
    }).done(function(data){
        $('#panier-contenu').html(data.panier);
    });
}

/**
 * Bon de réduction
 */
window.bout_checkBon = function(bon)
{
    if(bon == undefined || bon == "") return false;
    $.ajax({
        url:Routing.generate("panier-bon-reduction", {bon:bon}),
        type:'POST',
        dataType:'JSON'
    }).done(function(data){
        if(data.erreur != undefined) alert(data.erreur);
        else $('#panier-contenu').html(data.panier);
    });

}

/**
 * Compte client
 */
if((document.location.pathname === '/compte') || (document.location.pathname === '/panier/livraison')) {

    /* Montre la bonne adresse */
    $('.liste-adresse-livraison input[type=radio]').change(function () {
        if(this.checked) {
            adresseLivraison($(this));
            bout_displayShipping($(this).val());
        }
    });
    $('.liste-adresse-facturation input[type=radio]').change(function () {
        if(this.checked) {
            adresseFacturation($(this));
        }
    });

    function adresseLivraison(adresse) {
        if(document.location.pathname === '/panier/livraison') {
            $('#livraison_form').addClass('hidden');
        }
        let id = adresse.attr('id');
        id = id.split('-')[1];
        $('.liste-adresse-livraison .detail-adresse').addClass('hidden');
        $('#detail-livraison-'+id).removeClass('hidden');
        //On supprime les formulaires s'il y en a
        $('.liste-adresse-livraison form').remove();
    }
    function adresseFacturation(adresse) {
        if(document.location.pathname === '/panier/livraison') {
            $('#facturation_form').addClass('hidden');
        }
        let id = adresse.attr('id');
        id = id.split('-')[1];
        $('.liste-adresse-facturation .detail-adresse').addClass('hidden');
        $('#detail-facturation-'+id).removeClass('hidden');
        //On supprime les formulaires s'il y en a
        $('.liste-adresse-facturation form').remove();
    }

    /**
     * Fait apparaitre le form d'ajout d'adresse de livraison
     */
    $('#btn-livraison-ajout-form').on('click',function(){

        // On cache le détail des adresses
        $('.liste-adresse-livraison .detail-adresse').addClass('hidden');

        // On sélectione l'adresse none qui est caché pour déselectionner les boutons radios visibles
        $('#adresse_livraison-new').prop("checked", true);

        // On supprime les formulaires s'il y en a
        $('.liste-adresse-livraison form').remove();

        // Affichage
        var $groupe = document.getElementById('livraison_form');
        $groupe.disabled = false;
        $('#livraison_form').removeClass('hidden');

        // Expédition en fonction de la nouvelle adresse
        bout_displayShipping('', $('#adresse_livraison_pays').val());
        $(document).on('change', $('#adresse_livraison_pays'), function(){
            bout_displayShipping('', $('#adresse_livraison_pays').val());
        });
        $('#ajout-livraison').prop("checked", true);

    });

    /**
     * Fait apparaitre le form de modif d'adresse de facturation
     */
    $('#btn-facturation-ajout-form').on('click',function(){

        // On cache le détail des adresses
        $('.liste-adresse-facturation .detail-adresse').addClass('hidden');

        // On sélectione l'adresse none qui est caché pour déselectionner les boutons radios visibles
        $('#adresse_facturation-new').prop("checked", true);

        // On supprime les formulaires s'il y en a
        $('.liste-adresse-facturation form').remove();

        // Affichage
        var $groupe = document.getElementById('facturation_form');
        $groupe.disabled = false;
        $('#facturation_form').removeClass('hidden');
        $('#ajout-facturation').prop("checked", true);

    });

}

/**
 * Frais de port
 * <input radio> avec class input-expedition
 */

window.bout_displayShipping = function(adresse, pays = '', code_postal = '') {
    $.ajax({
        url:Routing.generate("panier-affiche-expeditions", {adresse:adresse, pays:pays, code_postal:code_postal}),
        type:'POST',
        dataType:'JSON'
    }).done(function(data){
        $('#types-expedition').html(data.html);
        bout_updateShipping();
    });
}

$(document).on('click', 'input.input-expedition', function(){
    bout_updateShipping();
});

window.bout_updateShipping = function () {

    let type_expedition = $("input[name=type_expedition]:checked").val();
    let adresse_livraison = $("input[name=adresse-livraison]:checked").val();

    if(type_expedition == undefined) return;

    $('#bt-paiement').attr("disabled", true);
    $('#bt-paiement').css("opacity", "0.4");

    $.ajax({
        url:Routing.generate("panier-modif-expedition", {type_expedition:type_expedition, adresse_livraison:adresse_livraison}),
        type:'POST',
        dataType:'JSON'
    }).done(function(data){
        $('#bt-paiement').attr("disabled", true);
        $('#bt-paiement').css("opacity", "0.4");
        $('#panier-total').html(data.panier);
    });

}